import React from "react"
import { getValue, renameImage } from "../../utils"
import { connect } from "react-redux"
import { Link, navigate } from "gatsby"
import { Box, Text, Flex, Button, Image } from "rebass/styled-components"
// import Address from "../UI/poi/Address"
import IconPlan from "../UI/icons/IconPlan"
import "moment/locale/fr"
import SquareBox from "../UI/SquareBox"
import { unescape } from "lodash"
import LazyLoad from "react-lazyload"
import Title from "../UI/poi/Title"
import Categorie from "../UI/poi/Categorie"
const Favoris = ({ favoris }) => {
  return (
    <>
      {favoris.map((favori, i) => {
        const title = unescape(favori.title).replace("&#039;", "'")
        return (
          <Box
            onClick={() => navigate(favori.path)}
            key={"fav-" + i}
            pb={2}
            sx={{
              position: "relative",
              cursor: "pointer",
              transition: "all 300ms ease",
              ":hover": {
                pl: 4,
                ":before": {
                  width: "10px",
                  opacity: 1,
                },
              },
              ":before": {
                content: '""',
                position: "absolute",
                top: 0,
                height: "90px",
                opacity: 0,
                bottom: 0,
                bg: "primary",
                left: 0,
                width: "0",
                borderTopRightRadius: 2,
                borderBottomRightRadius: 2,
                transition: "all 300ms ease",
              },
            }}
          >
            <Flex pl={0} pr={2} justifyContent="stretch">
              <Box minWidth={["90px"]} minHeight={["90px"]}>
                <SquareBox>
                  {getValue(
                    favori,
                    "field_logo_client",
                    "uri",
                    "/no-image.jpg"
                  ) && (
                    <LazyLoad>
                      <Image
                        width="100%"
                        src={renameImage(favori.logo) + "?vid=" + favori.nid}
                        alt={"logo de " + title}
                      />
                    </LazyLoad>
                  )}
                </SquareBox>
              </Box>
              <Box pl={5} width={[1]}>
                <Title fontSize="20rem !important" title={title} pr={4} />
                <Flex justifyContent="space-between" alignItems="center">
                  <Box>
                    <Flex alignItems="baseline" flexDirection="row" pb={1}>
                      <Categorie
                        fontSize="14rem"
                        name={favori.categories}
                        link={false}
                      />
                    </Flex>
                  </Box>
                </Flex>
              </Box>
              <Flex alignItems="center" minWidth={["125px"]}>
                <Link to={favori.path}>
                  <Button
                    variant="primary"
                    as="div"
                    width={[1]}
                    px={["5px", 3]}
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="flex-start"
                      flexDirection={"row"}
                      height="26px"
                      flexWrap={["nowrap"]}
                    >
                      <IconPlan icon="arrow_right" size="24" />
                      <Text pl={1} fontSize="12px" textAlign="left">
                        Voir la fiche
                      </Text>
                    </Flex>
                  </Button>
                </Link>
              </Flex>
            </Flex>
            <Box ml={2} height="1px" bg="#d8d8d8" />
          </Box>
        )
      })}
    </>
  )
}

const mapStateToProps = ({ currentUser }) => ({
  token: currentUser.token,
  favoris: currentUser.favoris,
})
export default connect(mapStateToProps)(Favoris)
