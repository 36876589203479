import React, { useState, useEffect } from "react"
import { Text, Heading, Flex, Box, Button } from "rebass/styled-components"
import { getValue } from "../../utils"
import { connect } from "react-redux"
import ModalCustom from "../UI/Modal"
import Block from "../UI/poi/Block"
import IconPlan from "../UI/icons/IconPlan"
import Modification from "./Modification"
import RdvUser from "./RdvUser"
import {
  getRdvList,
  getFavoris,
  logout,
} from "../../redux/actions/currentUserActions"
import moment from "moment"
import { useStaticQuery, graphql, Link } from "gatsby"
import Loader from "../UI/Loader"
import { userMenuItems } from "../layout/UserMenu"
import Favoris from "./Favoris"
import CancelAccount from "./CancelAccount"
import InscriptionPoi from "./InscriptionPoi"

const Account = ({ user, rdvs, location, dispatch }) => {
  const [activeTab, setActiveTab] = useState("profil")
  const [isOpen, setIsOpen] = useState(false)
  const [rdvLoading, setRdvLoading] = useState(true)
  useEffect(() => {}, [])
  useEffect(() => {
    setRdvLoading(true)
    if (activeTab === "rdvs") {
      // eslint-disable-next-line
      rdvs = rdvs || []
      dispatch(getRdvList()).then(() => setRdvLoading(false))
    }

    if (activeTab === "favoris") {
      // eslint-disable-next-line
      dispatch(getFavoris())
    }
    if (activeTab === "logout") {
      dispatch(logout())
    }
  }, [activeTab, dispatch])

  useEffect(() => {
    setIsOpen(false)
  }, [user])

  useEffect(() => {
    if (location.hash === "#rdvs") {
      setActiveTab("rdvs")
      return
    }
    if (location.hash === "#favoris") {
      setActiveTab("favoris")
      return
    }
    if (location.hash === "#inscription") {
      setActiveTab("inscription")
      return
    }
    if (location.hash === "#settings") {
      setIsOpen(true)
    }
    setActiveTab("profil")
  }, [location])

  const data = useStaticQuery(graphql`
    query LesVillesAccount {
      allLesVillesJson {
        nodes {
          nid
          title
        }
      }
    }
  `)

  const villeItem = data.allLesVillesJson.nodes.find(
    element => element.nid === getValue(user, "field_ville_ref", "nid", "")
  )

  const tabs2 = userMenuItems
    .filter(item => item.id !== "settings")
    .map(item => {
      item.content = <></>
      if (item.id === "profil") {
        item.content = (
          <Block title="Informations de compte" py={0}>
            <Text py={1}>
              Prénom : {getValue(user, "field_prenom", "value", false)}
            </Text>
            <Text py={1}>
              Nom : {getValue(user, "field_nom", "value", false)}
            </Text>
            <Text py={1}>
              Date de naissance : {getValue(user, "field_naissance", "value", false)}
            </Text>
            <Text py={1}>Email : {user.mail}</Text>
            <Text py={1}>
              Téléphone : {getValue(user, "field_phone", "number", "")}
            </Text>
            {villeItem && <Text py={1}>Ville : {villeItem.title}</Text>}
            <Link to="/mon-compte#settings">
              <Button py={1} mt={2}>
                <Flex alignItems="center">
                  <IconPlan icon="settings_outline" size="24" pr={2} />
                  Modifier mes informations
                </Flex>
              </Button>
            </Link>
            <ModalCustom
              isOpen={isOpen !== false}
              title="Modifier mes informations"
              setIsOpen={setIsOpen}
            >
              <Box p={4}>
                <Modification />
                <CancelAccount />
              </Box>
            </ModalCustom>
          </Block>
        )
      }
      if (item.id === "rdvs") {
        item.content = (
          <>
            {rdvLoading && <Loader message="Chargement en cours" />}
            {!rdvLoading && rdvs && (
              <>
                <Block title="Rendez-vous à venir" pt={0}>
                  {rdvs.filter(
                    rdv =>
                      getValue(rdv, "field_date_rdv", "value", "") >=
                      moment().format("X")
                  ).length === 0 && <Text>Pas de rendez-vous à venir</Text>}
                  {rdvs
                    .filter(
                      rdv =>
                        getValue(rdv, "field_date_rdv", "value", "") >=
                        moment().format("X")
                    )
                    .map((rdv, i) => (
                      <RdvUser key={rdv.nid} rdv={rdv} isPast={false} />
                    ))}
                </Block>
                {rdvs.filter(
                  rdv =>
                    getValue(rdv, "field_date_rdv", "value", "") <
                    moment().format("X")
                ).length > 0 && (
                  <Block title="Historique des rendez-vous">
                    {rdvs
                      .filter(
                        rdv =>
                          getValue(rdv, "field_date_rdv", "value", "") <
                          moment().format("X")
                      )
                      .map((rdv, i) => (
                        <RdvUser key={rdv.nid} rdv={rdv} isPast={true} />
                      ))}
                  </Block>
                )}
              </>
            )}
            {!rdvs && null}
          </>
        )
      }
      if (item.id === "favoris") {
        item.content = (
          <Block title="Mes favoris" py={0}>
            <Favoris />
          </Block>
        )
      }
      if (item.id === "inscription") {
        item.content = (
          <InscriptionPoi item={item}/>
        )
      }
      return item
    })
  if (!user) {
    return <></>
  }
  return (
    <Flex mx={-2} flexDirection={["column", "column", "row"]}>
      <Box px={2} width={[1, 1, 1 / 3]}>
        <Flex mx={-2} flexDirection={["row", "row", "column"]}>
          <Box px={2}>
            <Text color="primary">Bonjour</Text>
            <Heading pb={4} as="h1" variant="h2">
              {getValue(user, "field_prenom", "value", false)}{" "}
              {getValue(user, "field_nom", "value", false)}
            </Heading>
            {tabs2.map((tab, itab) => (
              <Link to={tab.link} key={itab}>
                <Button
                  display={["none", "none", "block"]}
                  variant={
                    tab.id === activeTab ? "textPlainActive" : "textOutline"
                  }
                  width="100%"
                  mb={2}
                >
                  <Flex alignItems="center">
                    <IconPlan icon={tab.icon} size="24" pr={2} />
                    {tab.title}
                  </Flex>
                </Button>
              </Link>
            ))}
          </Box>
          <Box px={2}>
            <Flex mx={-2} flexDirection={["row", "row", "column"]}>
              {tabs2.map((tab, itab) => (
                <Box px={2} key={itab}>
                  <Link to={tab.link} key={itab}>
                    <Button
                      display={["block", "block", "none"]}
                      key={itab}
                      variant={
                        tab.id === activeTab ? "textPlainActive" : "textOutline"
                      }
                      width="100%"
                    >
                      <IconPlan icon={tab.icon} size="24" />
                    </Button>
                  </Link>
                </Box>
              ))}
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Box px={2} width={[1, 1, 2 / 3]}>
        {tabs2
          .filter(tab => tab.id === activeTab)
          .map((tab, itab) => (
            <Box key={itab}>{tab.content}</Box>
          ))}
      </Box>
    </Flex>
  )
}
const mapStateToProps = state => ({
  user: state.currentUser.user,
  rdvs: state.currentUser.rdvs,
  location: state.global.location,
})
export default connect(mapStateToProps)(Account)
