import React, { useState } from "react"
import { Box, Flex, Button, Text } from "rebass/styled-components"
import Form from "../Form"
import Loader from "../UI/Loader"
import Message from "../UI/Message"
import ModalCustom from "../UI/Modal"
import IconPlan from "../UI/icons/IconPlan"

import { connect } from "react-redux"
import { cancelUser } from "../../redux/actions/currentUserActions"
import { getErrorMessage } from "../../utils"
import { Link } from "gatsby"

const CancelAccount = ({ dispatch, user }) => {
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const formData = {
    formId: "cancel",
    successMessage: "Votre compte a bien été supprimé.",
    loadingMessage: "Suppression du compte en cours",
    elements: [
      {
        type: "markup",
        markup: (
          <Box>
            <Text>
              Afin de confirmer la suppression de votre compte, veuillez taper
              votre addresse email dans le champ suivant:
            </Text>
          </Box>
        ),
        props: {
          width: 1,
        },
      },
      {
        type: "text",
        name: "confirm_mail",
        default: "",
        label: "Adresse email",
        props: {
          width: [1],
        },
        verification: function(values, data) {
          if (user.mail !== values.confirm_mail) {
            return "Veuillez confirmer votre adresse email pour supprimer votre compte."
          }
          return false
        },
      },
      {
        type: "markup",
        markup: <Box my={2} height="2px" bg="gray10" />,
        props: {
          width: 1,
        },
      },
      {
        type: "markup",
        markup: (
          <Flex alignItems="center" justifyContent="center" width={1}>
            <Button
              width={1}
              as="div"
              variant="primaryGradiant"
              onClick={() => setIsOpen(false)}
            >
              <Flex alignItems="center" justifyContent="center">
                <IconPlan icon="arrow_left" size="24" pr={2} />
                Retour
              </Flex>
            </Button>
          </Flex>
        ),
        props: {
          width: [1, 1 / 2],
        },
      },
      {
        type: "submit",
        label: (
          <Flex alignItems="center" justifyContent="center">
            <IconPlan icon="error_fill" size="24" pr={2} />
            Supprimer mon compte
          </Flex>
        ),
        props: {
          width: [1, 1 / 2],
          display: "flex",
          justifyContent: "center",
        },
        buttonProps: {
          width: [1],
          variant: "dangerOutline",
        },
      },
    ],
  }
  const onSubmit = (formValues, data, submitSuccess, submitError) => {
    setMessages([])
    dispatch(cancelUser(user.uid)).then(action => {
      if (action.type === "USER_CANCEL_FAIL") {
        submitError()
      }
      if (action.type === "USER_CANCEL_SUCCESS") {
        submitSuccess()
        setMessages([
          {
            status: "success",
            value: (
              <Text>
                Votre compte a bien été supprimé.{" "}
                <Link to="/logout">
                  <Text sx={{ textDecoration: "underline" }} mt={1}>
                    retour a l'accueil
                  </Text>
                </Link>
              </Text>
            ),
          },
        ])
      }
      if (action.type === "USER_UPDATE_FAIL") {
        submitError()
        setMessages([
          ...messages,
          { status: "error", value: getErrorMessage(action.error.response) },
        ])
      }
    })
  }
  return (
    <>
      <Flex alignItems="center" justifyContent="center">
        <Button
          as="div"
          py={1}
          mt={2}
          variant="dangerLink"
          onClick={() => setIsOpen(true)}
        >
          <Flex alignItems="center">
            <IconPlan icon="error_fill" size="24" pr={2} />
            Supprimer mon compte
          </Flex>
        </Button>
      </Flex>
      <ModalCustom
        isOpen={isOpen !== false}
        title="Supprimer mon compte"
        setIsOpen={setIsOpen}
        containerSx={{
          minWidth: ["100%", "auto"],
          maxWidth: ["100%", "540px"],
        }}
      >
        {messages.map((message, key) => (
          <Message
            key={key}
            status={message.status}
            mb={2}
            onClose={() =>
              setMessages(messages.filter((value, index) => index !== key))
            }
          >
            {message.value}
          </Message>
        ))}
        <Form
          data={formData}
          onSubmit={(formValues, data, submitSuccess) => {
            onSubmit(formValues, data, submitSuccess)
          }}
        />
      </ModalCustom>
    </>
  )
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(CancelAccount)
