import React, { useState } from "react"
import { getValue } from "../../utils"
import { Box } from "rebass/styled-components"
import Form from "../Form"
import { useStaticQuery, graphql } from "gatsby"
import Loader from "../UI/Loader"
import Message from "../UI/Message"

import { connect } from "react-redux"
import { update } from "../../redux/actions/currentUserActions"
import { getErrorMessage } from "../../utils"
const Modification = ({ dispatch, user }) => {
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const data = useStaticQuery(graphql`
    query LesVillesModif {
      allLesVillesJson {
        nodes {
          nid
          title
        }
      }
    }
  `)

  const villeOptions = data.allLesVillesJson.nodes.map(function(ville) {
    return {
      value: ville.nid,
      label: ville.title,
    }
  })
  const options = [
    {
      value: "",
      label: "aucun",
    },
    ...villeOptions,
  ]
  const formData = {
    formId: "update",
    successMessage: "Votre compte a bien été mis à jour.",
    loadingMessage: "Modification du compte en cours",
    elements: [
      {
        type: "text",
        name: "field_nom",
        default: getValue(user, "field_nom", "value", ""),
        label: "Nom",
        required: true,
        props: {
          width: [1, 1, 1 / 3],
        },
      },
      {
        type: "text",
        name: "field_prenom",
        default: getValue(user, "field_prenom", "value", ""),
        label: "Prénom",
        required: true,
        props: {
          width: [1, 1, 1 / 3],
        },
      },
      {
        type: "textDate",
        name: "field_naissance",
        default: getValue(user, "field_naissance", "value", "__.__.____"),
        label: "Date de naissance",
        placeholder: "JJ.MM.AAAA",
        // beforeChange: value => {
        //   let pattern = /^([0-2|_][0-9|_]|(3|_)[0-1|_])(\/)(((0|_)[0-9|_])|((1|_)[0-2|_]))(\/)[1-2|_][0-9|_][0-9|_][0-9|_]$/
        //   let result = pattern.test(value)
        //   return value
        // },
        required: true,
        props: {
          width: [1, 1, 1 / 3],
        },
      },
      {
        type: "text",
        name: "mail",
        default: user.mail,
        label: "Email",
        required: true,
        props: {
          width: [1, 1, 1 / 2],
        },
      },
      {
        type: "text",
        name: "field_phone",
        default: getValue(user, "field_phone", "number", ""),
        label: "Téléphone",
        required: true,
        props: {
          width: [1, 1, 1 / 4],
        },
      },
      {
        type: "select",
        name: "field_ville_ref",
        default: getValue(user, "field_ville_ref", "nid", ""),
        label: "Ville",
        required: true,
        options: options,
        props: {
          width: [1, 1, 1 / 4],
        },
      },
      {
        type: "markup",
        markup: <Box my={2} height="2px" bg="gray10" />,
        props: {
          width: 1,
        },
      },
      {
        type: "password",
        name: "current_pass",
        default: "",
        label: "Ancien mot de passe",
        props: {
          width: [1, 1, 1 / 2],
        },
        verification: function(values, data) {
          if (values.mail !== user.mail && values.current_pass === "") {
            return "Indiquez l'ancien mot de passe pour modifier votre adresse email."
          }
          return false
        },
      },
      {
        type: "password",
        name: "pass",
        default: "",
        label: "Nouveau mot de passe",
        passwordmeter: "mail",
        props: {
          width: [1, 1, 1 / 2],
        },
        verification: function(values, data) {
          if (
            values.current_pass === values.pass &&
            values.current_pass !== ""
          ) {
            return "le nouveau mot de passe est identique a l'ancien."
          }
          if (values.pass !== "" && values.current_pass === "") {
            return "Indiquez l'ancien mot de passe pour modifier votre mot de passe."
          }
          return false
        },
      },
      {
        type: "submit",
        label: "Enregister",
        props: {
          width: 1,
          display: "flex",
          justifyContent: "center",
        },
        buttonProps: {
          width: [1, 1 / 2],
          variant: "primaryGradiant",
        },
      },
    ],
  }
  const onSubmit = (formValues, formdata, submitSuccess, submitError) => {
    if (formValues.current_pass === "") {
      delete formValues.pass
      delete formValues.current_pass
      delete formValues.mail
    }
    formValues.field_prenom = {
      und: [{ value: formValues.field_prenom, format: null, safe_value: "" }],
    }
    formValues.field_nom = {
      und: [{ value: formValues.field_nom, format: null, safe_value: "" }],
    }
    formValues.field_naissance = {
      und: [
        { value: formValues.field_naissance, format: null, safe_value: "" },
      ],
    }
    formValues.field_ville = {
      und: [
        {
          value: options.filter(
            ville => ville.value === formValues.field_ville_ref
          )[0].label,
          format: null,
          safe_value: "",
        },
      ],
    }
    formValues.field_ville_ref = { und: { nid: formValues.field_ville_ref } }
    formValues.field_phone = {
      und: [
        {
          number: formValues.field_phone,
          country_codes: "nc",
          extension: null,
        },
      ],
    }

    setMessages([])
    dispatch(update(formValues, user.uid)).then(action => {
      if (action.type === "USER_UPDATE_SUCCESS") {
        // submitSuccess()
        // setMessages([...messages, { status: "success", value: "Votre compte a bien été mis à jour." }]);
      }
      if (action.type === "USER_UPDATE_FAIL") {
        submitError()
        setMessages([
          ...messages,
          { status: "error", value: getErrorMessage(action.error.response) },
        ])
      }
    })
  }
  return (
    <>
      {messages.map((message, key) => (
        <Message
          key={key}
          status={message.status}
          mb={2}
          onClose={() =>
            setMessages(messages.filter((value, index) => index !== key))
          }
        >
          {message.value}
        </Message>
      ))}
      <Form data={formData} onSubmit={onSubmit} />
    </>
  )
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(Modification)
