import React from "react"
import { Box } from "rebass/styled-components"
import Block from "../UI/poi/Block"
import Webform from "../Form/Webform"
const InscriptionPoi = ({ item }) => {
  return (
    <Box>
      <Block title={item.title}>
        <Webform
          nid={40838}
          onChange={values => {
            console.log(values, "values")
          }}
          formData={{}}
          submitButton={{
            type: "submit",
            label: "S'inscrire",
            props: {
              width: 1,
            },
            buttonProps: {
              width: [1, 1 / 2],
              variant: "primaryGradiant",
            },
          }}
        />
      </Block>
    </Box>
  )
}

export default InscriptionPoi
